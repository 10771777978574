import { gsap, ScrollTrigger, Draggable, MotionPathPlugin, CustomEase, } from "gsap/all";
import { curtain } from "./utils";
import Swup from 'swup';
import SwupJsPlugin from '@swup/js-plugin';








export function language() {

    console.log("language function triggered");

//updateContent 
// function updateContent(langData) {
//     //console.log(langData); // Log langData to check its value
//     document.querySelectorAll('[data-i18n]').forEach(element => {
//         const key = element.getAttribute('data-i18n');
//         element.textContent = langData[key];
//     });
// }

function updateContent(langData) {
    // Iterate over all elements with the data-i18n attribute
    document.querySelectorAll('[data-i18n]').forEach(element => {
        // Get the value of the data-i18n attribute
        const key = element.getAttribute('data-i18n');
        
        // Check if langData contains the key
        if (langData && langData[key] !== undefined) {
            // Update the element's textContent with the value from langData
            //element.textContent = langData[key];
            element.innerHTML = langData[key];
        } else {
            // Handle the case where langData does not contain the key
            console.error(`Key "${key}" not found in langData`);
        }
    });
}



// Function to set the language preference
function setLanguagePreference(lang) {
    localStorage.setItem('language', lang);
    // location.reload();
 }
async function fetchLanguageData(lang) {
    //const response = await import(`../languages/${lang}.json`);
    const response = await fetch(`../json/${lang}.json`);
    console.log("JSON FETCH succeded");
    return response.json(); 
}
// Function to change language  WORKING
async function changeLanguage(lang) {
    setLanguagePreference(lang);
    const langData = await fetchLanguageData(lang);
    updateContent(langData);
    //toggleArabicStylesheet(lang); // Toggle Arabic stylesheet
}





//changeLanguage();

// Function to change language  WITH MENU CLOSE old working!!!!

// function toggleMenu(callback) {
//     var menu = document.querySelector('.menu');
//     var mainContent = document.querySelector('#page-wrapper');
//     // console.log("KADJFBKLSDJFLKDSLBFLSDFKJLFSDBL");
//     menu.classList.toggle('show');

//     if (menu.classList.contains('show')) {
     
//         gsap.to(mainContent, { y: "60vh", scaleX: 0.96, duration: 0.3, ease: "power2.inOut", onComplete: callback });
        

//     } else {

//         gsap.to(mainContent, { y: 0, scaleX: 1, duration: 0.4, delay: 0.15, ease: CustomEase.create("custom", "M0,0 C0,0 0.28,0.003 0.373,0.025 0.503,0.055 0.55,0.234 0.71,0.672 0.84,1.031 1,1 1,1 "), onComplete: callback });
   
//     }
// }


// NEW VERSION WITH SCROLL SMOOTER

function toggleMenu(callback) {
    var menu = document.querySelector('.menu');
    var mainWrapper = document.querySelector('#smooth-wrapper');
    var mainContent = document.querySelector('#smooth-content');
    var mainPage = document.querySelector('#main-page');
    menu.classList.toggle('show');



    if (menu.classList.contains('show')) {

    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');

    if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {

         //if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {

            console.log("MENU OPEN - iOS")
            
            mainWrapper.style.position = 'fixed';
            gsap.set('body', { overflow: 'hidden'});
            gsap.set(mainContent, { overflow: 'hidden'});
            gsap.set(mainPage, { overflow: 'hidden'});
            mainPage.style.top = `-${scrollY}`;

            gsap.to(mainWrapper, {translateY: 60 + "vh", scaleX: 0.96, duration: 0.5,
                onPlay: () => { 
                // gsap.to(mainContent, {translateY: scrollY, duration: 1, ease: "power4.out",});
                },
                onComplete: () => {
                // window.scrollTo(0, parseInt(scrollY || '0') * -1);
                // mainWrapper.style.position = 'unset';
                }

            }); // Move main content down and scale width

            } else {

                console.log("MENU OPEN - DESKTOP")
                gsap.to(mainWrapper, {translateY: 60 + "vh", scaleX: 0.96, duration: 0.5, ease: "power4.out",});

            }

        } else {
           
          ////CLOSING 


          if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
              
            console.log("MENU CLOSE - iOS")
            gsap.to(mainWrapper, {translateY: 0, scaleX: 1, duration: 0.6,
               onComplete: () => {

                  mainWrapper.style.position = 'relative';
                  document.body.style.overflow = '';
                  gsap.set(mainContent, { overflow: ''});
                  gsap.set(mainPage, { overflow: ''});
                  mainPage.style.top = "";
           }
           }); 

           } else {

            console.log("MENU CLOSE - DESKTOP")
             gsap.to(mainWrapper, {translateY: 0, scaleX: 1, duration: 0.6, ease: "power4.out",});

         

          }

    }    
     
        
}



// Function to scroll smoothly to the top of the main area



function initLG() {
    var lbtns = document.querySelectorAll(".lbt");
     console.log("LANGUAGE INIT");
    lbtns.forEach(function(lbtn) {
        const dataLng   = lbtn.getAttribute('data-lng');
        const menuBtn = document.getElementById("menuBtn");

        var morph = gsap.timeline({paused: true, reversed:true});
        let dash1 = document.getElementById("dash1");
        let dash2 = document.getElementById("dash2");
        let dash3 = document.getElementById("dash3");
        let cross = document.getElementById("cross");
        morph.to("#dash3", { duration: 0.5, morphSVG:"#cross"});

        lbtn.addEventListener("click", () => {
            //curtain();
            const languageButtons = document.querySelectorAll('.lbt');
            languageButtons.forEach(btn => btn.classList.remove("active"));
        
            // Add the "active" class to the clicked button
            lbtn.classList.add("active");


            setTimeout(() => {

                //reverse button
                // let menuBTinner = document.getElementById("menuBtn-inner");
                // gsap.to(menuBTinner, {
                //   yPercent: 0, duration: 0.5,
                // })
                //morph.reverse();
                menuBtn.style.pointerEvents ="none";
                gsap.to(dash3, {autoAlpha: 1, duration: 0.2,
                          onComplete: () => {
                            gsap.to(dash1, {autoAlpha: 1, duration: 0.2})
                            gsap.to(dash2, {autoAlpha: 1, duration: 0.2,
                            onComplete: () => {
                              gsap.to(dash3, {autoAlpha:0, duration:0.1})
                              gsap.to(dash1, {xPercent: 0, duration: 0.3})
                              gsap.to(dash2, {xPercent: 0, duration: 0.3,})
                              menuBtn.style.pointerEvents ="all";
                          }
                        });
                       }
                      })
                    // gsap.to(".line2:nth-child(1)", {transformOrigin: "center center", duration: 0.3, rotation: 21, onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.25, x: 0,}) });
                    // gsap.to(".line2:nth-child(2)", { transformOrigin: "center center", duration: 0.3, rotation: 21,onComplete: ()=> gsap.to(".line2:nth-child(2)", {duration: 0.25, x: 0,}) });
                    //menuBtn.classList.remove("active");
                    
                    
                    //toggleMenu();
                                    
                    ScrollTrigger.getAll().forEach((st) => {
                    // st.kill();
                    //st.refresh();
                    });

             }, 500);  

            // gsap.set(preloaderIcon, {autoAlpha:0 });
            // gsap.set(preloader, {yPercent:200 });
            //gsap.to(preloader, {yPercent: -200, duration: 0.75, delay: 0.5, ease:"circ.inOut"});
           

            const allTxts = document.querySelectorAll('[data-i18n]');
            //console.log(allTxts);
            

            allTxts.forEach(function(allTxt) {

                gsap.to(allTxt, { autoAlpha: 0, duration: 0.3,
                    onComplete: () => {
                        changeLanguage(dataLng);
                        //ScrollTrigger.refresh();
                        // setTimeout(() => {
                            gsap.to(allTxt, { autoAlpha: 1, duration: 0.6, delay: 0.25,
                                onPlay: () => {
                                   
                                    // menu.classList.toggle('show');
                                    
                                }
                            })
                           
                        // }, 400);   
                    }
                })



            })

            
           
            // setTimeout(() => {
                
            //     // curtain();
            //     setTimeout(() => {
            //     changeLanguage(dataLng);
            //     ScrollTrigger.refresh();


            //     }, 200); //was 1200 with preloader


            // }, 700);
        
        })
    })

   
    
}


window.onload = initLG;
//initLG;
// console.log(init)


//TEST




// Call updateContent() on page load
window.addEventListener('DOMContentLoaded', async () => {
    const userPreferredLanguage = localStorage.getItem('language') || 'en';
    const langData = await fetchLanguageData(userPreferredLanguage);
    updateContent(langData);
    // toggleArabicStylesheet(userPreferredLanguage); '// https://medium.com/@nohanabil/building-a-multilingual-static-website-a-step-by-step-guide-7af238cc8505   //for different stylesheets


    //check active class language btn
      // Find the button with the data-lng attribute matching the preferred language
      const preferredLanguageButton = document.querySelector(`[data-lng="${userPreferredLanguage}"]`);
    
      // Remove "active" class from all language buttons
      const languageButtons = document.querySelectorAll('.lbt');
      languageButtons.forEach(btn => btn.classList.remove('active'));
  
      // Add "active" class to the preferred language button
      if (preferredLanguageButton) {
          preferredLanguageButton.classList.add('active');
      }



});


return {
    initLG: initLG,
    updateContent: updateContent,
    fetchLanguageData:  fetchLanguageData,
    // Other functions or variables
};

// console.log(init)


}
