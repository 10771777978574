import { gsap, ScrollTrigger, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, MorphSVGPlugin, ScrollSmoother } from "gsap/all";

import Swup from 'swup';
import SwupJsPlugin from '@swup/js-plugin';
// import SwupHeadPlugin from '@swup/head-plugin';
// import SwupScriptsPlugin from '@swup/scripts-plugin';
import { getCurrentUrl, updateHistoryRecord } from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
// import SwupDebugPlugin from '@swup/debug-plugin';
// import SwupProgressPlugin from '@swup/progress-plugin';
// import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';
import { Observer } from "gsap/Observer";

import Swiper from 'swiper';
import SwiperCore from 'swiper';
import { FreeMode, Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";


// import InfiniteMenu from './infinitemenu.js';
import lozad from 'lozad';
// import LazyLoad from "vanilla-lazyload";

import { header, mainlogo, wheelBtn } from './header.js';

import { preloader } from './preloader.js';
import { footer, footerCases } from './footer.js';
import { language } from "./language.js";
import { claim } from './homeAni.js';
import { imagesCaseAni, updateFooterImages } from './caseAni.js';
import { videoPlaybackHome, clock, curtain, footerAni, contactBtnFunc, indexBtnFunc, capAni, videoControl, emailCode, showEmailOnClick } from './utils.js';
// import { allwork, allworkCreateMenu } from './allwork.js';



//const allwork = require('./allwork.js');
//const allworkCreateMenu = require('./allwork.js');
const workMenuFunc = require('./allwork.js');
// import { allwork, allworkCreateMenu } from './allwork.js';



;(function () {
    


    

  console.log("CHECK && loadAllFunctions");
  gsap.registerPlugin (ScrollTrigger, CustomEase, ScrollToPlugin, MorphSVGPlugin, ScrollSmoother, MotionPathPlugin, Observer, Draggable );



  window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  });


  preloader();

 


  let smoother = ScrollSmoother.create({
    wrapper: "#smooth-wrapper",
    smooth: 1.5, // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true, // looks for data-speed and data-lag attributes on elements
    smoothTouch: 0, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
   //normalizeScroll: true, //Bug returns when this is set to true
    // ignoreMobileResize: true,
  
    // normalizeScroll: true,
        onUpdate: (self) => {
        // getScrollPos = function() {
        //     var scrollY = self.scrollTop();
        //     console.log(scrollY);
        //     console.log(getScrollPos);
        // }
       }
    
  });
  

  function mainFunc() {

      header();     
      mainlogo();
      clock();
      language();
      contactBtnFunc();
      wheelBtn();
      workMenuFunc();
      emailCode();
      

      // menuInitialized = false;
      // if (!menuInitialized) {
      //               workMenuFunc();
      //               menuInitialized = true;
             

      //       } else {

      //         console.log("")

      //       }

     
    



      console.log(window.location.pathname);

      console.log("mainFunction TRIGGERED");
      gsap.set("#preloaderIcon", {autoAlpha:1});
      gsap.set("#main-logo", { autoAlpha: 0,});
      // gsap.set("#workmenu", { autoAlpha: 0,yPercent: -100});
      gsap.set("#smooth-wrapper", { autoAlpha: 1});
    
      let preloader = document.getElementById("preloader");

      //smoother.scrollTo(1000, false );
      // console.log("smoother.scrollTo")

      //gsap.to(window, {scrollTo:0,});
      gsap.to(preloader, {yPercent: -200, duration: 1.2, delay: 1, ease:"circ.inOut", 
          onPlay: () => {
            gsap.to("#main-logo", { autoAlpha: 1, duration: 0.4});
            gsap.to("#main-header", { autoAlpha: 1, duration: 0.4});
            gsap.to("#smooth-wrapper", { autoAlpha: 1, duration: 0.4});
          },
      })

      const observerImages = lozad();
      // setTimeout(() => {
      //   observerImages.observe();
      // }, 20000);
      observerImages.observe();

      lozad('.lozad', {
        loaded: function(el) {
            // Custom implementation on a loaded element
            el.classList.add('loaded');
        }
      });

    
     
      // var lazyLoadInstance = new LazyLoad(
      //   {
      //   // Your custom settings go here
      //   threshold: "800",
      //   }
      // );
      // //lazyLoadInstance();
      // lazyLoadInstance.update();

      /* If browser back button was used, flush cache */
      (function () {
        window.onpageshow = function(event) {
          if (event.persisted) {
            window.location.reload();
            console.log("RELOAD triggered by BACK button")
          }
        };
      })();

      //--vh INNER HEIGHT VARIABLE
      const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight/100 + 'px');
      //console.log("var --vh SET")
      }

      /* WINDOW addEventListeners */
      window.addEventListener('resize', appHeight)
      appHeight();
      window.removeEventListener('orientationchange', async () => {
        await delay(80)
        ScrollTrigger.refresh()
      })


      // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      //   //alert("This is an iOS device.");
      //   } else {
      //   //alert("This is not an iOS device!");
      //      // create the scrollSmoother before your scrollTriggers

      //   }

 

  








                  

        // // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE  
        // // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE  
        // // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE  
        // // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE   // DARK / LIGHT MODE  


        // Function to check user's system preference for dark mode
function checkSystemDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

// Function to toggle dark mode
function toggleDarkMode() {
  const darkModeSwitch = document.getElementById('dark-mode-switch');
  const isDarkMode = darkModeSwitch.checked;

  if (isDarkMode) {
    // Enable dark mode
    localStorage.setItem('themeChoice', 'dark');
    document.documentElement.setAttribute('data-theme', 'dark');
  } else {
    // Enable light mode
    localStorage.setItem('themeChoice', 'light');
    document.documentElement.setAttribute('data-theme', 'light');
  }
}

// Function to set theme based on user's selection
function setTheme(theme) {
  localStorage.setItem('themeChoice', theme);
  document.documentElement.setAttribute('data-theme', theme);
}

// Retrieve the theme choice from local storage
const currentTheme = localStorage.getItem('themeChoice');

// Check if a theme has been set in localStorage
if (currentTheme) {
  setTheme(currentTheme);
  document.getElementById('dark-mode-switch').checked = currentTheme === 'dark';
} else {
  // If no theme is set, check system dark mode preference
  const systemDarkMode = checkSystemDarkMode();
  if (systemDarkMode) {
    setTheme('dark');
    document.getElementById('dark-mode-switch').checked = true;
  } else {
    setTheme('light');
    document.getElementById('dark-mode-switch').checked = false;
  }
}

// Event listener for dark mode switch
document.getElementById('dark-mode-switch').addEventListener('change', toggleDarkMode);

const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
toggleSwitch.addEventListener('change', function (event) {
  if (event.target.checked) {
    localStorage.setItem('themeChoice', 'dark');
    // Apply dark mode styles
    // bgChange();
  } else {
    localStorage.setItem('themeChoice', 'light');
    // Apply light mode styles
    // bgChange();
    // Reset background colors to default for light mode
    document.querySelector('.main').style.backgroundColor = '#eae9e6';
    // document.querySelector(".crs").style.backgroundColor = "transparent"; // Or your default cursor color
  }
});

                  // // Function to check user's system preference for dark mode
                  // function checkSystemDarkMode() {
                  //     return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                  // }

                  // // Function to toggle dark mode
                  // function toggleDarkMode() {
                  //     const darkModeSwitch = document.getElementById('dark-mode-switch');
                  //     const isDarkMode = darkModeSwitch.checked;

                  //     if (isDarkMode) {
                  //         // Enable dark mode
                  //         localStorage.setItem('themeChoice', 'dark');
                  //         document.documentElement.setAttribute('data-theme', 'dark');
                         

                  //     } else {
                  //         // Enable light mode
                  //         localStorage.setItem('themeChoice', 'light');
                  //         document.documentElement.setAttribute('data-theme', 'light');
                  //         document.getElementById("dark-mode-switch").checked = "false";
                  //     }
                  // }

                  // // Function to set theme based on user's selection
                  // function setTheme(theme) {
                  //     localStorage.setItem('themeChoice', theme);
                  //     document.documentElement.setAttribute('data-theme', theme);
                  // }
                  
                  // // Retrieve the theme choice from local storage
                  // const currentTheme = localStorage.getItem('themeChoice');
                  // document.documentElement.setAttribute('data-theme', currentTheme);

                

                  // if (currentTheme === 'dark' || currentTheme === 'light') {
                  //   setTheme(currentTheme);
                  //   document.getElementById("dark-mode-switch").checked = "true";
                  // } else {
                  //   const systemDarkMode = checkSystemDarkMode();
                  //   if (systemDarkMode) {
                  //       setTheme('dark');
                       
                      
                  //   } else {
                  //       setTheme('light');
                      
                  //   }
                  // }


                  // // Set the switch based on the theme choice
                  // document.getElementById("dark-mode-switch").checked = currentTheme === "dark";

                  // // Event listener for dark mode switch
                  // document.getElementById('dark-mode-switch').addEventListener('change', toggleDarkMode);

                  // const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
                  // toggleSwitch.addEventListener('change', function(event) {
                  //     if (event.target.checked) {
                  //         localStorage.setItem('themeChoice', 'dark');
                  //         // Apply dark mode styles
                  //         // bgChange();
                  //     } else {
                  //         localStorage.setItem('themeChoice', 'light');
                  //         // Apply light mode styles
                  //         // bgChange();
                  //         // Reset background colors to default for light mode
                  //         document.querySelector(".main").style.backgroundColor = "#eae9e6";
                  //         // document.querySelector(".crs").style.backgroundColor = "transparent"; // Or your default cursor color
                  //     }
                  // });




              //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION
              //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION
              //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION
              //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION



              menuBtn.addEventListener("click", function() {
                gsap.to("#case-info-inner", { autoAlpha: 0, duration: 0.3});
                console.log("menu clicked")
                if (!this.classList.contains("active")) {

                 

                  var caseInfoTxt =  document.getElementById('#case-info-inner');
                  if (typeof(caseInfoTxt) != 'undefined' && caseInfoTxt != null)
                  {
                    gsap.to("#case-info-inner", { autoAlpha: isOpen ? 0 : 1, duration: 0.3});
                    //gsap.to("#case-info-inner", { autoAlpha: 0, duration: 0.3});
                  } 

                  let menuBTinner = document.getElementById("menuBtn-inner");
                  gsap.to(menuBTinner, {
                    yPercent: -100, duration: 0.5,
                    onPlay: () => {
                      menuBtn.style.pointerEvents ="none";
                    },
                    onComplete: () => {
                      menuBtn.style.pointerEvents ="all";
                    }
                  })
               
                  //gsap.to(dash1, {xPercent: 20, duration: .3});
                  // gsap.to(dash2, {xPercent: -20, duration: .3,
                  //       onPlay: () => {
                  //         menuBtn.style.pointerEvents ="none";
                  //       },
                  //       onComplete: () => {
                  //           // gsap.to(dash1, {autoAlpha: 0, duration: 0.2})
                  //           // gsap.to(dash2, {autoAlpha: 0, duration: 0.2})
                  //           gsap.to(dash3, {autoAlpha:1, duration:0.25,
                  //           onPlay: () => {
                  //             // console.log("PALY")
                  //             //morph.to("#dash3", { duration: 0.5, morphSVG:"#cross",
                  //               //morph.play();
                  //               // onComplete: () => {
                  //               //   menuBtn.style.pointerEvents ="all";
                  //               // }});
                  //           },
                  //           onComplete: () => {
                  //             menuBtn.style.pointerEvents ="all";
                  //           }})
                  //       }});
                  // // morph.play();
            
                } else {
                  
                      
                      menuBtn.style.pointerEvents ="none";
                     
                      var caseInfoTxt =  document.getElementById('#case-info-inner');
                      if (typeof(caseInfoTxt) != 'undefined' && caseInfoTxt != null)
                      {
                        //gsap.to("#case-info-inner", {autoAlpha:1, duration: 0.3,
                        gsap.to("#case-info-inner", { autoAlpha: isOpen ? 0 : 1, duration: 0.3,
                         
                        });
                      } 
                     
                      menuBtn.style.pointerEvents ="all";
                      let menuBTinner = document.getElementById("menuBtn-inner");
                      gsap.to(menuBTinner, {
                        yPercent: 0, duration: 0.5,
                      })
                      gsap.to("#case-info-inner", { autoAlpha: 1, duration: 0.3});

                
                 
                  
                  }
                    
                this.classList.toggle("active");

                
              });






              let menuInitialized = false; // Flag to track if menu has been initialized

              //menuBtn = document.getElementById("menuBtn");
              document.getElementById('menuBtn').addEventListener('click', function() {

                toggleMenu();

                // if (!menuInitialized) {
                //   console.log("MENU ALREADY INITIALIZED")
                //    workMenuFunc();
                // }

                // setTimeout( function() {


                //   let closeWorkMenu = document.getElementById("closeWorkMenu");
                //   closeWorkMenu.addEventListener("click", () => {
                //   console.log("CLOSE CLICKED")
                //   let workMenu = document.getElementById("workmenu");
                 
                //   let isOpen
               
                //   isOpen = !isOpen
                //   gsap.to(workMenu, {
                //     autoAlpha: isOpen ? 0 : 1, duration: 0.5,
                //   })
                
                //   smoother.paused(false);
                //   })

                //   }, 500);
                 
                  menuInitialized = true; // Set flag to true to indicate menu initialization
              });

              


                  // // //MENU BUTTON / CHANGED NO HAMBURGER ANYMORE

                  // const menuBtn = document.getElementById("menuBtn");

                  // menuBtn.addEventListener("click", function() {
                  //   console.log("menu clicked #1")
                  //   if (!this.classList.contains("active")) {

                  //     var caseInfoTxt =  document.getElementById('case-info-inner');
                  //     if (typeof(caseInfoTxt) != 'undefined' && caseInfoTxt != null)
                  //     {
                  //       //gsap.to("#case-info-inner", {autoAlpha: isOpen ? 0 : 1, duration: 0.3,
                  //       gsap.to("#case-info-inner", {autoAlpha:0,  duration: 0.3,
                  //         onPlay: () => {
                  //           console.log("case-info-inner goes dark #1")
                  //         }
                  //       });
                  //     } 
                  //     let menuBTinner = document.getElementById("menuBtn-inner");
                  //     gsap.to(menuBTinner, { yPercent: -100, duration: 0.5,

                  //                 onPlay: () => {
                  //                   menuBtn.style.pointerEvents ="none";
                  //                 },
                  //                 onComplete: () => {
                  //                   menuBtn.style.pointerEvents ="all";
                  //                 }
                  //     })
                   
                  //   } else {
                      
                  //     menuBtn.style.pointerEvents ="none";
                  //     var caseInfoTxt =  document.getElementById('case-info-inner');
                  //     if (typeof(caseInfoTxt) != 'undefined' && caseInfoTxt != null)
                  //     {
                  //       gsap.to("#case-info-inner", {autoAlpha:1, duration: .3});
                  //     } 
                  //     menuBtn.style.pointerEvents ="all";
                  //     let menuBTinner = document.getElementById("menuBtn-inner");
                  //     gsap.to(menuBTinner, {
                  //       yPercent: 0, duration: 0.5,
                  //     })
                
                  //   }
                        
                  //   this.classList.toggle("active");

                    
                  // }); //END OF menuBTNClick

                  // let menuInitialized = false; // Flag to track if menu has been initialized

                  // //menuBtn = document.getElementById("menuBtn");
                  // document.getElementById('menuBtn').addEventListener('click', function() {
                  //   console.log("menu clicked #2")
                  //   toggleMenu();

                  //   if (!menuInitialized) {
                  //     console.log("MENU ALREADY INITIALIZED")
                  //      workMenuFunc();
                  //   }

                  //   setTimeout( function() {
                  //     let closeWorkMenu = document.getElementById("closeWorkMenu");
                  //     closeWorkMenu.addEventListener("click", () => {
                  //     console.log("CLOSE CLICKED")
                  //     let workMenu = document.getElementById("workmenu");
                     
                  //     let isOpen
                   
                  //     isOpen = !isOpen
                  //     gsap.to(workMenu, {
                  //       autoAlpha: isOpen ? 0 : 1, duration: 0.5,
                  //     })
                    
                  //     smoother.paused(false);
                  //     })

                  //     }, 500);
                     
                  //     menuInitialized = true; // Set flag to true to indicate menu initialization
                  // });

                  




                  // // FUNCTION REVERSE NEW BUTTON
                  
                  // function reverseHamburgerAnimation() {

                      
                  //     morph.reverse();
                  //     menuBtn.style.pointerEvents ="none";
                  //     gsap.to(dash3, {autoAlpha: 1, duration: 0.2,
                  //               onComplete: () => {
                  //                 gsap.to(dash1, {autoAlpha: 1, duration: 0.2})
                  //                 gsap.to(dash2, {autoAlpha: 1, duration: 0.2,
                  //                 onComplete: () => {
                  //                   gsap.to(dash3, {autoAlpha:0, duration:0.1})
                  //                   gsap.to(dash1, {xPercent: 0, duration: 0.3})
                  //                   gsap.to(dash2, {xPercent: 0, duration: 0.3,})
                  //                   menuBtn.style.pointerEvents ="all";
                  //               }
                  //             });
                  //            }
                  //           })

                  //      menuBtn.classList.remove("active");
                  // }

                  function reverseMenuBtnAnimation() {

                    
                      menuBtn.style.pointerEvents ="none";
                      setTimeout( function() {
                        menuBtn.style.pointerEvents ="all";
                        menuBtn.classList.remove("active");
                      },1000);
                     
                  }

                  
                  //PREVENT CLICK IF PAGE IS STILL SCROLLING
                  const scrollCont = document.querySelector("#smooth-wrapper");
                  let isScrolling = false;
                  let scrollPos = 0;
                  let scrollTimer;
                  scrollCont.addEventListener("scroll", () => {
                    const newScrollPos = scrollCont.scrollTop;
                
                    // Define a tolerance range, for example, 100px
                    const tolerance = 100;
                
                    if (Math.abs(newScrollPos - scrollPos) > tolerance) {
                        
                        isScrolling = true;
                        clearTimeout(scrollTimer);
                        //console.log(newScrollPos);
                        menuBtn.style.pointerEvents ="none";
                    } else {
                        
                    
                        clearTimeout(scrollTimer);
                        scrollTimer = setTimeout(() => {
                            isScrolling = false;
                            //alert("Scrolling stopped");
                            //hamburger.style.pointerEvents ="all";
                            menuBtn.style.pointerEvents ="all";
                            // Do something when scrolling stops

                        }, 100); // Adjust the delay as needed
                    }
                    scrollPos = newScrollPos; // Update scroll position
                  });



                

                // MENU TOGGEL MENU TOGGLE    MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE
                // MENU TOGGEL MENU TOGGLE    MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE  
                // MENU TOGGEL MENU TOGGLE    MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE
                // MENU TOGGEL MENU TOGGLE    MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE   MENU TOGGEL MENU TOGGLE

                  // Function to toggle menu
                  function toggleMenu() {
                      var menu = document.querySelector('.menu');
                      var mainWrapper = document.querySelector('#smooth-wrapper');
                      var mainContent = document.querySelector('#smooth-content');
                      var mainPage = document.querySelector('#main-page');
                      menu.classList.toggle('show');

                      // Animation using GSAP
                      if (menu.classList.contains('show')) {

                        
                        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                        //console.log(scrollY);
                        const body = document.body;

                        //console.log(scrollY);
                        //body.style.position = 'fixed';
                        //innerContainer.style.position = 'fixed';
                        // innerContainer.style.top = `-${scrollY}`;
                        //innerContainer.style.transform = "matrix3d(1, 0, 0, 0,    0, 1, 0, 0,       0, 0, 1, 0,    0, -2144, 0, 1)
                       // body.style.position = 'fixed';
                       // body.style.top = `-${scrollY}`;


                          //gsap.set('body', { overflow: 'hidden'});
                          //gsap.set(mainContent, { overflow: 'hidden', position: "fixed", height: 100 + "vh"});
                          // mainWrapper.style.position = 'fixed';
                          // mainContent.style.top = `-${scrollY}`;
                      
                         //if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                          if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
                            console.log("MENU OPEN - iOS");

                           // smoother.paused(true);

                            mainWrapper.style.position = 'fixed';
                            gsap.set('body', { overflow: 'hidden'});
                            gsap.set(mainContent, { overflow: 'hidden'});
                            gsap.set(mainPage, { overflow: 'hidden'});
                            mainPage.style.top = `-${scrollY}`;
                            //body.style.position = 'fixed';
                            //body.style.top = `-${scrollY}`;

                            gsap.to(mainWrapper, {translateY: 60 + "vh", scaleX: 0.96, duration: 0.5,
                                onPlay: () => { 
                                  // gsap.to(mainContent, {translateY: scrollY, duration: 1, ease: "power4.out",});
                                },
                                onComplete: () => {
                                  // window.scrollTo(0, parseInt(scrollY || '0') * -1);
                                  // mainWrapper.style.position = 'unset';
                                }

                            }); // Move main content down and scale width

                           } else {

                              console.log("MENU OPEN - DESKTOP")
                              gsap.to(mainWrapper, {translateY: 60 + "vh", scaleX: 0.96, duration: 0.5, ease: "power4.out",});

                          }

                        ///  gsap.to(mainWrapper, {translateY: 60 + "vh", scaleX: 0.96, duration: 1, ease: "power4.out",
                         //   onComplete: () => {
                              //window.scrollTo(0, parseInt(scrollY || '0') * -1);
                              //gsap.to(innerContainer, {transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -2144 , 0, 1)}); 
                              // gsap.set("#smooth-wrapper", {overflowY: "scroll"})
                              // console.log("CHECK SCROLLWRAPPER")
                            
                          //  }  
                        //}); // Move main content down and scale width
                        // }, 2000);
                      } else {
                        






                        ////CLOSING 
                        if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
                       //if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                            
                          console.log("MENU CLOSE - iOS")
                          gsap.to(mainWrapper, {translateY: 0, scaleX: 1, duration: 0.6,
                             onComplete: () => {
                                // window.scrollTo(0, parseInt(scrollY || '0') * -1);
                                mainWrapper.style.position = 'relative';
                                document.body.style.overflow = '';
                                gsap.set(mainContent, { overflow: ''});
                                gsap.set(mainPage, { overflow: ''});
                                mainPage.style.top = "";
                               // smoother.paused(false);
                         }
                         }); // Move main content down and scale width

                         } else {

                          console.log("MENU CLOSE - DESKTOP")
                           gsap.to(mainWrapper, {translateY: 0, scaleX: 1, duration: 0.6, ease: "power4.out",});

                          //window.scrollTo(0, parseInt(scrollY || '0') * -1);

                        }


                          // // gsap.to(mainContent, { translateY: "0", scaleX: 1, duration: 0.6, delay: 0.15, ease: CustomEase.create("custom", "M0,0 C0,0 0.28,0.003 0.373,0.025 0.503,0.055 0.55,0.234 0.71,0.672 0.84,1.031 1,1 1,1 ") }); // Move main content back up and restore width
                          // gsap.to(mainWrapper, {translateY: 0, scaleX: 1,  duration: 0.6, delay: 0.2, ease: "power2.out",
                          //         onComplete: () => { 



                                    
                          //           // mainWrapper.style.position = ''; 
                                  

                          //           // mainContent.style.position = '';
                          //           //       const scrollY = body.style.top;
                          //           //       body.style.position = '';
                          //           //       body.style.top = '';
                          //           //       window.scrollTo(0, parseInt(scrollY || '0') * -1);

                          //         }
                        
                          // });// Move main content back up and restore width
                          // //ScrollTrigger.refresh();

                      }
                  }

                  // Function to close menu
                  function closeMenu() {
                      var menu = document.querySelector('.menu');
                      var mainWrapper = document.querySelector('#smooth-wrapper');
                      menu.classList.remove('show');
                    
                      // gsap.set('body', { overflow: 'unset'});
                      gsap.to(mainWrapper, { translateY: "0", scaleX: 1, duration: 0.8, delay: 0.15, ease: CustomEase.create("custom", "M0,0 C0,0 0.28,0.003 0.373,0.025 0.503,0.055 0.55,0.234 0.71,0.672 0.84,1.031 1,1 1,1 "),
                                // onComplete: () => {     mainWrapper.style.position = '';   }
                                }); // Move main content back up and restore width
                     // gsap.to(mainContent, { translateY: "0", scaleX: 1, duration: 1.5, ease: "power4.out"}); // Move main content back up and restore width

                    }



                // mouse
              $('html').on('mousewheel DOMMouseScroll', function (e) { 

                 // console.log("SCROLLING")
                 var menu = document.querySelector('.menu');
                 var mainWrapper = document.querySelector('#smooth-wrapper');
                 var mainContent = document.querySelector('#smooth-content');
                 var mainPage = document.querySelector('#smooth-content');
                      if (menu.classList.contains('show')) {
                          //reverseHamburgerAnimation();
                          reverseMenuBtnAnimation();
                          closeMenu(); // Close menu if open and user scrolls within main content
                          let menuBTinner = document.getElementById("menuBtn-inner");
                          gsap.to(menuBTinner, {
                            yPercent: 0, duration: 0.5,
                          })
                          setTimeout(function() {
                            gsap.set('body', { overflow: 'unset'});
                          },1000)
                      }

              })




        
            const workMenuOpen = function() {

              
              // MENU WORK BTN

              let workBtn =  document.getElementById("workBtn");
              let isOpen = false; // Declare isOpen globally to keep track of the state

              workBtn.addEventListener("click", () => {
                console.log("workBtn clicked")
                
                let indexLogoContInner = document.getElementById("indexWheelBtn-container-inner");
                    gsap.to(indexLogoContInner, {  
                      yPercent: isOpen ? 0 : -44, 
                      duration: 0.25, 
                      transformOrigin: "top left", 
                      ease: "power2.inOut" 
                    })


                let workMenu = document.getElementById("workmenu");
           
                isOpen = !isOpen
                gsap.to("#case-info", { autoAlpha: isOpen ? 0 : 1, duration: 0.3,
                  onPlay: ()=> {
                    console.log("case-info-inner goes dark #2")
                  }
                });
                gsap.to(workMenu, {
                  // yPercent: isOpen ? 0 : -100,
                  autoAlpha: isOpen ? 1 : 0,
                  duration: 0.5,
                  onComplete: () => {
                    if (!isOpen) {
                      // Menu is closed, remove "item__active" class from all elements with the class "menu__item"
                      let menuItems = document.querySelectorAll(".menu__item");
                      menuItems.forEach(item => {
                        item.classList.remove("active__item");
                      })
                    }
                  }
                });
                smoother.paused(isOpen)
                })



               

                setTimeout(function() {
                  let workBtnIndex = document.getElementById("indexWheelBtn");
                   workBtnIndex.addEventListener("click", () => {

                    //BUTTON CHANGE

                  
                    let indexLogoContInner = document.getElementById("indexWheelBtn-container-inner");
                    gsap.to(indexLogoContInner, {  
                      yPercent: isOpen ? 0 : -44, 
                      duration: 0.25, 
                      transformOrigin: "top left", 
                      ease: "power2.inOut" 
                    })
                
              
                    //MENU FADE IN /OUT

                    let workMenu = document.getElementById("workmenu");
                
                    // Toggle the isOpen state
                    isOpen = !isOpen;
                
                    // Perform the animation based on the isOpen state
                    gsap.to("#case-info", { autoAlpha: isOpen ? 0 : 1, duration: 0.3,
                      onPlay: () => {
                        console.log("case-info-inner goes dark #3")
                      }
                    });
                    gsap.to(workMenu, {
                      // yPercent: isOpen ? 0 : -100,
                      autoAlpha: isOpen ? 1 : 0,
                      duration: 0.5,
                      onComplete: () => {
                        if (!isOpen) {
                          // Menu is closed, remove "item__active" class from all elements with the class "menu__item"
                          let menuItems = document.querySelectorAll(".menu__item");
                          menuItems.forEach(item => {
                            item.classList.remove("active__item");
                          })
                        }
                      }
                    });
                
                    smoother.paused(isOpen);
                  });
                }, 1000);

                





                setTimeout(function() {
                  let workBtnFooter = document.getElementById("workBtnFooter");
                   workBtnFooter.addEventListener("click", () => {

                    //BUTTON CHANGE

                  
                    let indexLogoContInner = document.getElementById("indexWheelBtn-container-inner");
                    gsap.to(indexLogoContInner, {  
                      yPercent: isOpen ? 0 : -44, 
                      duration: 0.25, 
                      transformOrigin: "top left", 
                      ease: "power2.inOut" 
                    })
                
              
                    //MENU FADE IN /OUT

                    let workMenu = document.getElementById("workmenu");
                
                    // Toggle the isOpen state
                    isOpen = !isOpen;
                
                    // Perform the animation based on the isOpen state
                    gsap.to("#case-info", {autoAlpha: 0, duration: 0.3});
                    gsap.to(workMenu, {
                      // yPercent: isOpen ? 0 : -100,
                      autoAlpha: isOpen ? 1 : 0,
                      duration: 0.5,
                      onComplete: () => {
                        if (!isOpen) {
                          // Menu is closed, remove "item__active" class from all elements with the class "menu__item"
                          let menuItems = document.querySelectorAll(".menu__item");
                          menuItems.forEach(item => {
                            item.classList.remove("active__item");
                          })
                        }
                      }
                    });
                
                    smoother.paused(isOpen);
                  });
                }, 1000);





                setTimeout(function() {
                let workBtnFooter =  document.getElementById("workBtnFooter");
                let isOpen = false; // Declare isOpen globally to keep track of the state
 

                workBtnFooter.addEventListener("click", () => {

                  let indexLogoContInner = document.getElementById("indexWheelBtn-container-inner");
                  gsap.to(indexLogoContInner, {  
                    yPercent: isOpen ? 0 : -44, 
                    duration: 0.25, 
                    transformOrigin: "top left", 
                    ease: "power2.inOut" 
                  })
                    
                  let workMenu = document.getElementById("workmenu");
                   // Toggle the isOpen state
                  isOpen = !isOpen

                  gsap.to("#case-info", {autoAlpha:1, duration: 0.2});
                  gsap.to(workMenu, {
                    // autoAlpha: isOpen ? 1 : 0, 
                   //yPercent: 0, autoAlpha:1,duration: 0.5,
                   autoAlpha: isOpen ? 1 : 0,
                   duration: 0.5,
                    onPlay: () => { 
                      
                    },
                    onComplete: () => {
                     
                    }
                  })
                 
                  smoother.paused(isOpen)
                  })
                }, 2000);

                
                  
                
                

                  setTimeout(function() {
                    let workBtnIndex =  document.getElementById("indexWheelBtn");
                    workBtnIndex.addEventListener("click", () => {
                      let workMenu = document.getElementById("workmenu");
                      let isOpen
                  
                      isOpen = !isOpen
                      // gsap.to("#case-info", {autoAlpha:1, duration: 0.2});
                      gsap.to(workMenu, {
                        // autoAlpha: isOpen ? 1 : 0, 
                        yPercent: 0, autoAlpha:1,duration: 0.5,
                      })
                      smoother.paused(isOpen)
                      })
                    }, 200);


             }
             workMenuOpen();


           
              
            // const jadeScale = document.querySelectorAll("footer");
            // const options = {rootMargin: "-150px"};
           
            // const jadeScaleObserver = new IntersectionObserver (function (entries, observer) {
            //   entries.forEach(function(entry) {
            //     if (entry.isIntersecting) {
            //      console.log("INtersection")
            //      if (!menuInitialized) {
            //             workMenuFunc();
            //             menuInitialized = true;
            //      }

            //     } else {

            //       console.log("OUT not intersection")

            //     }
            //   });
            // }, options);

            // jadeScale.forEach ( function (jadeScale) {
            //   jadeScaleObserver.observe (jadeScale);
            // });

           


            //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      
            //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      
            //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      //   DOMAIN CHECK   //      




   
        var currURL = window.location.pathname;
        console.log("current URL CHECK = " + currURL);
    
            // if (window.location.href.indexOf('/') != -1) {
           if ((window.location.pathname.indexOf('/index') != -1) || (currURL === '/')) {
             // if ((currURL === '/') ||  (currURL === '/index.html')) {  
              // let back = document.getElementById("backHOME");
              // back.style.visibility = "hidden";
              console.log("INDEX  INDEX   INDEX   INDEX 1st");
              footer();
              // footerAni();
              videoPlaybackHome();
              claim();
              emailCode();
              showEmailOnClick();
              
            

            // } else if (window.location.href.indexOf('case') != -1) {
            } else if (window.location.pathname.indexOf('work') != -1) {

              SwiperCore.use([FreeMode, Autoplay, Mousewheel, Navigation, Pagination]);

              console.log("CASE   CASE    CASE    CASE1 st");
              footerCases();
              wheelBtn();
              //footerAni();
              imagesCaseAni();
              videoPlaybackHome();
              videoControl();
              emailCode();
            

              // init Swiper:
              var swiper = new Swiper(".swiper", {
                slidesPerView: "1",
                loopAdditionalSlides: 2,
                spaceBetween: 0,
                loop: true,
                // autoplay: {
                //     delay: 3000,
                //     disableOnInteraction: false,
                //     pauseOnMouseEnter: true,

                //   },
                direction: 'horizontal',
                // freeMode: {
                // enabled: true,
                // minimumVelocity: 0.02,
                // momentum: true,
                // momentumRatio: 5,
                // momentumVelocityRatio: 0.1,
                // momentumBounce: false,
                // },
                // mousewheel: {
                //   forceToAxis: true,
                // },
                // simulateTouch: "true",
                // draggable: true,
                pagination: {
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  // clickable: true,
                },
               
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },

                });
             
             
            
            } else if (window.location.href.indexOf('studio') != -1) {
              console.log("STUDIO STUDIO STUDIO STUDIO 1st");
            
              footer();
              // footerAni();
              imagesCaseAni();
              // capAni();
              emailCode();
           
            } else if (window.location.href.indexOf('contact') != -1) {
              console.log("CONTACT  CONTACT  CONTACT  CONTACT 1st");
              footer();
              // footerAni();
              imagesCaseAni();
              language();
            
              const langModule = language();

            } else if (window.location.href.indexOf('thank') != -1) {

              console.log("THANK YOU THANK YOU 1st");
              footer();
              // footerAni();
              imagesCaseAni();
              language();

            } else if (window.location.href.indexOf('legal-notice') != -1) {
              console.log("LEGAL LEGAL  LEGAL  LEGAL 1st");
              footer();
              // footerAni();
              imagesCaseAni();
              emailCode();

              // let STT = document.getElementById('ScrollToTop');
              
              // STT.addEventListener('click', () => {
              //   smoother.scrollTo(0, true)
              // })


       
              let down2data = document.getElementById("down2data");
              down2data.addEventListener("click", (e) => {
                  smoother.scrollTo("#data-protection", true, "top 10%");
              }) 
            } else {
              //alert("An error has occurred!");
          }// END IF CHECK WHICH DOMAIN


 
  
  };

  mainFunc();
  
 
       



          // SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        
        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        
          // SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        //   SWUP   //        



        const swup = new Swup({

          plugins: [new SwupJsPlugin({
          containers: ['#smooth-content'],
          animations: [  
                {
                  from: '(.*)',
                  to: '(./work/*.*)',
                  out: (done) => {
                        console.log("from / to ANY --> OUT");
                  
                    
                    var menu = document.querySelector('.menu');
                    
                    if (menu.classList.contains('show')) {
                              console.log("MENU OPEN");
                              var mainContent = document.querySelector('#smooth-wrapper');
                              menu.classList.toggle('show');
                              gsap.set('body', { overflow: 'unset'});
                              gsap.to(".line2:nth-child(1)", {transformOrigin: "center center", duration: 0.3, rotation: 21,
                              onPlay: () => {menuBtn.style.pointerEvents ="none"},
                              onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.25, x: 0,}) })
                              gsap.to(".line2:nth-child(2)", { transformOrigin: "center center", duration: 0.3, rotation: 21, 
                              onComplete: ()=> {
                                gsap.to(".line2:nth-child(2)", {duration: 0.25, x: 0,});
                                setTimeout(function() {
                                  menuBtn.style.pointerEvents ="all";
                                },300)
                              }}, 0);
                              gsap.to(mainContent, {scaleX: 1, translateY: "0",  duration: 0.6, delay: 0.2, ease: "power2.out"});// Move main content back up and restore width
                              ScrollTrigger.refresh();

                              setTimeout(function(){
                                gsap.set("#preloaderIcon", {autoAlpha:0 });
                                gsap.set("#preloader", { yPercent: 200});
                                gsap.to("#preloader", {yPercent: 0, duration: 0.75, delay: 0.1, ease:"circ.inOut",
                                 onComplete: () => {
                                   setTimeout(done, 500);
                                
                                },
                              })
                              
                              
                            }, 500);
                          
                        
                            } else {
                              console.log("MENU CLOSED")
                              gsap.set("#preloaderIcon", {autoAlpha:0 });
                              gsap.set("#preloader", { yPercent: 200})
                              gsap.to("#preloader", {yPercent: 0, duration: 0.75, delay: 0.2, ease:"circ.inOut",
                                  onComplete: () => {
                                    setTimeout(done, 500);
                                  } 
                              })

                    }
                
                  },
                  in: (done) => {
                    console.log("from / to ANY --> IN");
                  
                    gsap.set("#preloaderIcon", {autoAlpha:0 });
                    gsap.set("#preloader", { yPercent: 0})
                    gsap.to("#preloader", {yPercent: -200, duration: 0.75, delay: 0.1, ease:"circ.inOut",
                              onPlay: () => {
                                
                                gsap.to("#main-logo", { autoAlpha: 1, duration: 0.5});
                                gsap.to("#main-header", { autoAlpha: 1, duration: 0.5});
                                gsap.to("#smooth-wrapper", { autoAlpha: 1, duration: 0.5});
                                
                                document.getElementById('menuBtn').style.pointerEvents ="all";
                              },
                                onComplete: () => {
                                  setTimeout(done, 50000)
                                },
                             })
                  }
              },

              {
                from: '(.*)',
                to: '(.*)',
                out: (done) => {
                      console.log("from /fdslkfl;sgks;ldkfg to ANY --> OUT");
                
                  
                  var menu = document.querySelector('.menu');
                  
                  if (menu.classList.contains('show')) {
                            console.log("MENU OPEN");
                            var mainContent = document.querySelector('#smooth-wrapper');
                            menu.classList.toggle('show');
                            gsap.set('body', { overflow: 'unset'});
                            gsap.to(".line2:nth-child(1)", {transformOrigin: "center center", duration: 0.3, rotation: 21,
                            onPlay: () => {menuBtn.style.pointerEvents ="none"},
                            onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.25, x: 0,}) })
                            gsap.to(".line2:nth-child(2)", { transformOrigin: "center center", duration: 0.3, rotation: 21, 
                            onComplete: ()=> {
                              gsap.to(".line2:nth-child(2)", {duration: 0.25, x: 0,});
                              setTimeout(function() {
                                menuBtn.style.pointerEvents ="all";
                              },300)
                            }}, 0);
                            gsap.to(mainContent, {scaleX: 1, translateY: "0",  duration: 0.6, delay: 0.2, ease: "power2.out"});// Move main content back up and restore width
                            ScrollTrigger.refresh();

                            setTimeout(function(){
                              gsap.set("#preloaderIcon", {autoAlpha:0 });
                              gsap.set("#preloader", { yPercent: 200});
                              gsap.to("#preloader", {yPercent: 0, duration: 0.75, delay: 0.1, ease:"circ.inOut",
                               onComplete: () => {
                                 setTimeout(done, 500);
                              
                              },
                            })
                            
                            
                          }, 500);
                        
                      
                          } else {
                            console.log("MENU CLOSED")
                            gsap.set("#preloaderIcon", {autoAlpha:0 });
                            gsap.set("#preloader", { yPercent: 200})
                            gsap.to("#preloader", {yPercent: 0, duration: 0.75, delay: 0.2, ease:"circ.inOut",
                                onComplete: () => {
                                  setTimeout(done, 500);
                                } 
                            })

                  }
              
                },
                in: (done) => {
                  console.log("from / fdgsdfgdgdfs to ANY --> IN");
                
                  gsap.set("#preloaderIcon", {autoAlpha:0 });
                  gsap.set("#preloader", { yPercent: 0})
                  gsap.to("#preloader", {yPercent: -200, duration: 0.75, delay: 0.1, ease:"circ.inOut",
                            onPlay: () => {
                              
                              gsap.to("#main-logo", { autoAlpha: 1, duration: 0.5});
                              gsap.to("#main-header", { autoAlpha: 1, duration: 0.5});
                              gsap.to("#smooth-wrapper", { autoAlpha: 1, duration: 0.5});
                              
                              document.getElementById('menuBtn').style.pointerEvents ="all";
                            },
                              onComplete: () => {
                                setTimeout(done, 500)
                              },
                           })
                }
            },
          

            
              ],  //END SWUP ANIM
              
              // resolveUrl: (url) => {
              //   if (url.startsWith('/work/?')) {
              //     return '/cases/';
              //   }
              //   return url;
              // },
            
            }),

            // new SwupDebugPlugin(),
            new SwupPreloadPlugin(),
            new SwupGtmPlugin(),
            new SwupScrollPlugin({
              offset: 0,
              getAnchorElement: true,
              animateScroll: {
                betweenPages: true,
                samePageWithHash: true,
                samePage: true
              },

            }),
            
            ], // END SWUP PLUGIN
          

          });

            
            //swup.hooks.on('content:replace', init);
            
            function init() {
            
            

              window.dataLayer.push({
                event: 'VirtualPageview',
                virtualPageURL: window.location.pathname + window.location.search,
                virtualPageTitle: document.title
              });



              new mainlogo();
              //new header();
              var currURL = getCurrentUrl();
              console.log("Content Replace Hook, current URL = " + currURL);
              new mainFunc();
              const langModule = language();
              langModule.initLG();


              let contactContInner = document.getElementById("contact-container-inner");
              let contactContOuter = document.getElementById("contact-container-outer");
              gsap.set(contactContOuter, { yPercent: 0}); 
              gsap.set(contactContInner, {  yPercent: -120 });
          
              
              ScrollTrigger.getAll().forEach((st) => {
                st.kill();
                st.update();
              });

              // gsap.set(".main-nav", { autoAlpha:0});
              // gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
            

              // LOCATION CHECK
              var currURL = window.location.pathname;
              console.log(currURL);
              if ((currURL === '/') ||  (currURL === '/index.html')) {  
              //if (window.location.href.indexOf('index') != -1) {
                
                 console.log("INIT Swup HOME IN");
                
              
                 footer();
                 //footerAni();
                 videoPlaybackHome();
                 imageAni();
                 claim();
                 homeTeaser();
                 contactBtnFunc();
                 language();
                 emailCode();
                


                 gsap.set(".main-nav", { autoAlpha:0});
                //  gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                gsap.set("#smooth-wrapper", { autoAlpha:0 });


                gsap.to(window, {scrollTo:0,});
                
                      } else if (window.location.pathname.indexOf('work') != -1) { 
                      // } else if (window.location.href.indexOf('/work/') != -1) {

                          console.log("INIT Swup CASE IN");
                          gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                          gsap.set(".main-nav", { autoAlpha:0});
                          //new footerCases();
                          //new footerAni();
                          new imagesCaseAni();
                          new contactBtnFunc();
                          document.getElementById("contactCont").style.opacity = 1;
                      
                          ScrollTrigger.getAll().forEach((st) => {
                            //console.log(st);
                          });
                
                                  } else if (window.location.href.indexOf('studio') != -1) {

                                    console.log("INIT Swup STUDIO");
                                    gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                                    gsap.set(".main-nav", { autoAlpha:0});
                                    document.getElementById("contactCont").style.opacity = 1;
                                    new footer();
                                   // new footerAni();
                                    new imagesCaseAni();
                                    new contactBtnFunc();
            
                                            } else if (window.location.href.indexOf('contact') != -1) {

                                              console.log("INIT Swup CONTACT");
                                              gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                                              gsap.set(".main-nav", { autoAlpha:0});
                                              document.getElementById("contactCont").style.opacity = 1;
                                              new footer();
                                              //new footerAni();
                                              new imagesCaseAni();

                                                } else if (window.location.href.indexOf('thank') != -1) {

                                                  console.log("INIT Swup THANK YOU");
                                                  gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                                                  gsap.set(".main-nav", { autoAlpha:0});
                                                  document.getElementById("contactCont").style.opacity = 1;
                                                  new footer();
                                                  //new footerAni();
                                                  new imagesCaseAni();
                                                
                                            
                                                      } else if (window.location.href.indexOf('legal-notice') != -1) {

                                                        console.log("INIT Swup LEGAL NOTICE");
                                                        if (window.location.href.indexOf('data-protection') != -1) {
                                                          console.log("DATA PROTECTION")
                                                          //gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                                                        }else {
                                                          gsap.set("#smooth-wrapper", { autoAlpha:0, scrollTo: 0});
                                                        }

                                                      
                                                        gsap.set(".main-nav", { autoAlpha:0});
                                                        document.getElementById("contactCont").style.opacity = 1;
                                                        new footer();
                                                        //new footerAni();
                                                        new imagesCaseAni();
                                                        new contactBtnFunc();
                                                        
                                                              

                                                                              } else {
                                                                                  console.log("undefined target")
                                                                              }
                };
            
                const langModule = language();
            
              swup.hooks.on('page:view', async () => {
                console.log("PAGEVIEW Hook");
                const userPreferredLanguage = localStorage.getItem('language') || 'en';
                const langData = await langModule.fetchLanguageData(userPreferredLanguage);
                langModule.updateContent(langData);

              
                // langModule.initLG();
                // init();
                // mainFunc();
                // language();
                // footer();
                // footerAni();

              });

        // // Run once when page loads
        // if (document.readyState === 'complete') {
        //   init();
        // } else {
        //   document.addEventListener('DOMContentLoaded', () => init());
        // }
        // // Run after every additional navigation by swup


        swup.hooks.on('page:view', () => init());


})(); //global END


